@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./variables";

@import "./components/OnboardingSlides/style";
@import "./components/Loading/style";
@import "./routing/layout-routes/auth-lr/style";

@import "../node_modules/bootstrap/scss/bootstrap";

* {
  font-family: "Nunito", sans-serif;
}

h1,
.title,
.subtitle {
  font-family: "Poppins", sans-serif;
}

.App {
  min-height: 100vh;
  text-align: center;
}

.title {
  font-weight: 500;
  font-size: xx-large;
  color: $big-text-color;
  line-height: 1.2;
}

.subtitle {
  font-size: 15px;
}

.dark-blue-text {
  color: $big-text-color;
}

.round-button {
  border-radius: 50%;
  padding: 10px;
  border: none;
  background-color: #ced1db;
  box-shadow: 0px 2px rgb(100, 100, 100);
}

.form-label {
  font-weight: 700;
  color: $big-text-color;
}

.form-control {
  padding: 16px;
}

.primary-button {
  background-color: $primary-color;
  border: 0;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  padding: 12px 20px;
  box-shadow: 0px 6px $primary-shadow-color;
  color: white !important;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.primary-outline-button {
  border: 2px $primary-color solid;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  padding: 12px 20px;
  background: transparent;
  color: $primary-color;
}

.primary-transparent-button {
  border: 0;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  background: transparent;
  color: $primary-color;

  &:disabled {
    opacity: 0.8;
  }
}

.secondary-button {
  background-color: $secondary-color;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  padding: 14px 20px;
  box-shadow: 0px 6px $secondary-shadow-color;
  color: white;
}

.primary-dropdown-button {
  button {
    @extend .primary-button;
    background-color: $primary-color !important;

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0px 6px $primary-shadow-color !important;
    }
  }

  .dropdown-item {
    font-weight: 700;
    font-size: 16px;
    padding: 12px 20px;
    color: $primary-color !important;
    background: white;

    &:hover {
      background-color: $primary-color !important;
      color: white !important;
    }
  }

  .dropdown-menu {
    padding: 0 !important;
    box-shadow: 0 0px 10px 3px #00000042;
    border-radius: 8px !important;
    overflow: clip;
  }

  &-yellow {
    @extend .primary-dropdown-button;

    button {
      background-color: #ffb000 !important;
      box-shadow: 0px 6px #D69400 !important;

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0px 6px #D69400 !important;
      }
    }
  }
}

.nav-link-selected {
  background-color: $primary-color;
  color: white;
  min-width: 175px;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  padding: 12px;
}

a {
  color: $primary-color;
  font-weight: 600;

  &:hover {
    color: $primary-color-dark;
  }
}

.link-button {
  text-decoration: none;

  &:hover {
    color: white;
  }
}

input,
.form-control {
  &:focus {
    background-color: rgba(247, 231, 253, 0.286);
    box-shadow: 0px 0px 0px 1px $primary-color inset !important;
    border: 1px solid $primary-color;
  }

  &:disabled {
    background-color: rgba(247, 231, 253, 0.286);
  }
}

@media (max-width: 575px) {
  .profile-picture-container .primary-button {
    margin: 16px 0px;
  }

  .profile-picture-container .primary-transparent-button {
    margin-right: 10px;
  }
}

@font-face {
  font-family: "satoshi-medium";
  src: url("./utils/fonts/satoshi/Satoshi-Medium.otf");
}

@font-face {
  font-family: "satoshi";
  src: url("./utils/fonts/satoshi/Satoshi-Variable.ttf");
}

.font-satoshi {
  font-family: "satoshi" !important;
}

.font-satoshi-medium {
  font-family: "satoshi-medium" !important;
}

.border-blue {
  border: 1px solid #05c7f2;
}

.border-orange {
  border: 1px solid #fa8431;
}

.border-yellow {
  border: 1px solid #ffb000;
}

@include media-breakpoint-down(sm) {
  .min-w-100 {
    min-width: 100% !important;
  }

  .pt-2-sm {
    padding-top: 0.5rem !important;
  }
}

@include media-breakpoint-up(sm) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}

@include media-breakpoint-up(md) {
  .w-md-auto {
    width: auto !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .decorative-img-md-1 {
    top: -80px !important;
    left: -80px !important;
  }

  .decorative-img-md-2 {
    top: -90px !important;
    right: -80px !important;
  }

  .decorative-img-md-3 {
    bottom: -50px !important;
    right: -10px !important;
  }

  .decorative-img-md-4 {
    bottom: -50px !important;
    left: -20px !important;
  }
}

@include media-breakpoint-up(lg) {
  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }
}

.alert-box {
  .modal-dialog {
    .modal-content {
      border: none;
      border-radius: 16px;
      padding: 40px 32px;
    }
  }
}

.secondary-text {
  color: $secondary-color;
}



/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.form-check-label {
  color: $big-text-color;
  font-weight: 600;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  border: 0;
  padding: 4px;
  box-shadow: 0 0 0 2px $big-text-color;

  &:focus {
    box-shadow: 0 0 0 2px $big-text-color;
  }
}

.form-check-input:checked {
  background-color: $big-text-color;
}

.rotate-180 {
  transform: rotateZ(180deg);
  transition: 0.2s ease;
}

.rotate-0 {
  transform: rotateZ(0deg);
  transition: 0.2s ease;
}

.recharts-tooltip-wrapper {
  border: none;
  outline: none;
  height: auto;
  width: auto;
}

.custom-multi-select {
  input {
    &:focus {
      box-shadow: none !important;
    }
  }

  &.invalid {
    .react-select__control {
      border-color: var(--bs-form-invalid-border-color) !important;

      .react-select__indicator-separator {
        background-color: var(--bs-form-invalid-border-color) !important;
        opacity: 0.7;
      }

      .css-1xc3v61-indicatorContainer {
        color: var(--bs-form-invalid-border-color) !important;
        opacity: 0.6;

        &:hover {
          color: var(--bs-form-invalid-border-color) !important;
          opacity: 1;
        }
      }

      .css-15lsz6c-indicatorContainer {
        color: var(--bs-form-invalid-border-color) !important;
        opacity: 0.6;

        &:hover {
          color: var(--bs-form-invalid-border-color) !important;
          opacity: 1;
        }
      }
    }
  }

  .react-select__control {
    border-radius: 8px !important;
    min-height: 52px;
    border: 1px solid #ced1db;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #313c69;

    .react-select__value-container {
      height: 100% !important;
      padding: 0 8px !important;
    }

    .css-jzldcf-Input {
      margin: 0 !important;
      padding: 0 !important;
    }

    .css-6j8wv5-Input {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.tag {
  height: 42px;
  padding: 8px 12px;
  width: fit-content;
  border-radius: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
}

.file-tag {
  background: #fbf6fd;
  color: #a429d9;
}

.submit-tag {
  background: #fff2e2;
  color: #d98a29;
}

.name-tag {
  background: #eff8fc;
  color: #05c7f2;
}


.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.css-wsp0cs-MultiValueGeneric {
  font-size: 100% !important;
}

.form-select {
  background-color: transparent;

  &:focus {
    background-color: transparent;
    box-shadow: 0px 0px 0px 1px #6e6e6e inset !important;
    border: 1px solid #6e6e6e;
  }
}

.box-shadow-none {
  box-shadow: none !important;
}

.payment-form {
  height: 100vh;
  overflow-y: auto;

  .contact-info {
    border-left: 6px solid $primary-color;
    padding-left: 10px;
  }
}