.child-dropdown {
  position: relative;
  min-width: 280px;
  background-color: white;
  box-shadow: 0 0 0 1px #ced1db;

  .custom-multi-select {
    position: absolute;
    top: 56px;
    z-index: 8;
    width: 100%;
    text-align: start;
    box-shadow: 0 0 0 1px #ced1db;
    .react-select__control {
      padding: 8px;
      border: none;
      box-shadow: none;
      border-radius: 0 !important;
      background-color: white !important;
    }
    .react-select__menu {
      border: none;
      box-shadow: none;
      border-radius: 0 0 12px 12px !important;
      border-radius: 0 0 12px 12px;
      box-shadow: 0 0 0 1px #ced1db;
      top: 44px;
      overflow: hidden;
    }
    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
    .react-select__control {
      .react-select__value-container {
        padding: 0 !important;
      }
    }
  }
  .dropdown-option-img-container {
    border-radius: 12px;
    width: 40px;
    height: 40px;
    aspect-ratio: 1/1;
    .child-image {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-position: center;
      object-fit: cover;
    }
  }
}

.select-rounded-corner {
  border-radius: 8px;
}

.select-rounded-upper-corner {
  border-radius: 8px 8px 0 0;
}
