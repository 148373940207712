.notification-button {
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  display: block;
  width: 54px;
  height: 54px;
  .notification-count {
    position: absolute;
    top: 8px;
    right: 4px;
    background-color: #ffbf00;
    color: black;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: x-small;
    padding: 2px;
    box-sizing: content-box;
  }
}
.profile-dropdown {
  .dropdown {
    .dropdown-toggle::after {
      display: none;
    }
    button {
      display: flex;
      align-items: center;
      padding: 0;
      box-shadow: none;
      .profile-pic {
        width: 54px;
        height: 54px;
        border-radius: 8px;
        background-size: contain;
        background-color: #ffffff;
      }
      .profile-data {
        .profile-name {
          font-size: 20px;
          font-weight: 500;
          margin: 0;
        }
        .profile-designation {
          font-size: 14px;
        }
      }
    }
  }
}

.menu-button {
  color: black;
}
