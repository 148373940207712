.account-layout {
  background-color: #fafbfe;
  min-height: 100vh;
  .open-width {
    width: calc(100% - 300px);
  }
  .close-width {
    width: calc(100% - 150px);
  }
}

@media screen and (max-width: 600px) {
  .account-layout {
    .side-nav-wrapper {
      position: absolute;
      z-index: 99;
      .open-shadow {
        box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.523);
      }
    }
    .open-width {
      width: 100%;
    }
    .close-width {
      width: 100%;
    }
  }
}
