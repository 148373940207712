.mobile-header {
  .profile-dropdown {
    .dropdown {
      button {
        .profile-pic {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

.menu-button {
  background-color: transparent;
  border: none;
}
.notification-button {
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  display: block;
  width: 48px;
  height: 48px;
  .notification-count {
    position: absolute;
    top: 8px;
    right: 4px;
    background-color: #ffbf00;
    color: black;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: x-small;
    padding: 2px;
    box-sizing: content-box;
  }
}
