@import "../../variables.scss";

.new-tag {
  display: inline;
  background-color: #fa8431;
  border-radius: 20px;
  color: white;
  padding: 4px 12px;
  margin-left: 16px;
  font-size: small;
}

.collapsed-new-tag {
  position: absolute;
  top: 0;
  right: 28px;
  font-size: 12px;
  padding: 2px 6px;
  margin-left: 0;
}

.sidebar-container {
  background-color: white;
  border-bottom-right-radius: 32px;
  border-top-right-radius: 32px;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.108);
  min-height: 90vh;
  padding: 20px 10px;
  position: relative;
  .app-logo {
    width: 100%;
    .small-logo {
      width: 60%;
      height: auto;
    }
    .full-logo {
      width: 80%;
      height: auto;
    }
  }

  .link-container {
    margin-top: 36px;
    width: 100%;
    a {
      color: $big-text-color;
      padding: 16px;
      font-size: 16px;
      text-decoration: none;
      margin-bottom: 24px;
      border-radius: 8px;
      .link-content {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 8px;
          svg {
            width: 24px;
            height: 24px;
            stroke: $big-text-color !important;
            path {
              stroke: $big-text-color !important;
            }
          }
          .feather-icon {
            stroke-width: 1.38;
          }
        }
      }
    }
    .child-profile-wrapper {
      width: 100%;
    }
  }

  .active-link-class {
    .new-tag {
      display: none;
    }
  }

  .open {
    a {
      .link-content {
        flex-direction: row;
      }
    }
    .active-link-class {
      background-color: $primary-color;
      color: white;
      .link-content {
        .icon {
          svg {
            stroke: white !important;
            path {
              stroke: white !important;
            }
          }
        }
      }
    }
  }
  .close {
    a {
      padding: 0 !important;
      margin-bottom: 24px;
      .link-content {
        .icon {
          padding: 16px;
          margin: 0;
          margin-bottom: 8px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .active-link-class {
      .link-content {
        .icon {
          background-color: $primary-color;
          border-radius: 8px;
          margin-right: 0 !important;
          svg {
            stroke: white !important;
            path {
              stroke: white !important;
            }
          }
        }
      }
    }
  }
}

.toggle-button {
  z-index: 2;
  width: 32px;
  height: 32px;
  position: absolute;
  right: -16px;
  top: 10%;
  background-color: gainsboro;
  border: none;
  border-radius: 50%;
  padding: 0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.281);
}

.toggle-button-mobile {
  z-index: 2;
  width: 42px;
  height: 42px;
  position: absolute;
  right: -20px;
  top: 5%;
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 0;
  padding-bottom: 2px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.281);
}

.open {
  width: 300px;
  transition: 0.25s ease;
  padding-right: 16px;
  padding-left: 16px;
}

.close {
  width: 150px;
  transition: 0.25s ease;
}

.support-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 170px;
  button,
  .support-buttons {
    position: absolute;
    margin-top: 80px;
    width: 100%;
    background-color: #f5feff;
    border-radius: 16px;
    height: 80px;
    border: 0.5px solid #05c7f2;
    color: $big-text-color;
    font-weight: 600;
    padding: 20px 0 0 0;
  }
  .support-buttons {
    margin-top: 100px;
    a {
      img {
        width: 44px;
        height: 44px;
      }
    }
  }
  img {
    z-index: 2;
  }
}

.logout-button {
  background: transparent;
  border: 0;
  color: $big-text-color;
  font-weight: 500;
  width: 100%;
  padding: 0;
  margin: 0;
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: $big-text-color;
      stroke-width: 1px;
    }
  }

  .button-content {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .open {
    flex-direction: row;
    margin-top: 36px;
    margin-bottom: 8px;
    padding-left: 29px;
    span {
      margin-left: 8px;
    }
  }
  .close {
    flex-direction: column;
    margin-top: 18px;
    .icon {
      padding: 16px;
    }
    span {
      margin-bottom: 8px;
    }
  }
}

.popover-support-link {
  padding: 12px !important;
  .support-link {
    img {
      width: 48px;
    }
  }
}

@media screen and (max-width: 750px) {
  .open {
    width: 250px;
    transition: 0.25s ease;
    padding-right: 12px;
    padding-left: 8px;
    .child-profile-wrapper {
      display: flex !important;
    }
  }
  .close {
    width: 0px;
    transition: 0.25s ease;
    padding: 0;
    * {
      display: none !important;
    }
  }
}

.div-link-text {
  font-weight: 600;
  font-size: 16px;
}
