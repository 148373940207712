@import "/src/variables.scss";

.dropdown-child-profile-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 80px;
  border: none;
  background-color: #ffffff;
  padding: 8px;
  padding-left: 12px;
  border-radius: 8px;
  width: 100%;
  .selected-child-name {
    width: 50%;
    text-align: start;
    color: $big-text-color;
    font-weight: 600;
  }
  .children-image-wrapper {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    .child-image-container {
      position: relative;
      border-radius: 12px;
      width: 40px;
      height: 40px;
      aspect-ratio: 1/1;
      .child-image {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-position: center;
        object-fit: cover;
      }
      .selected-tick {
        position: absolute;
        bottom: 4px;
        left: -4px;
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
          background-color: white;
          border-radius: 50%;
        }
      }
    }
  }

  .not-selected-child {
    opacity: 0.4;
    .selected-tick {
      display: none;
    }
  }

  .add-child-btn {
    padding: 6px;
    width: 32px;
    height: 32px;
    border: 2px dashed $primary-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
