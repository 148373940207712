.otp-input {
  .form-control {
    width: 60px !important;
    height: 52px !important;
    text-align: center;
    font-size: large;
    font-weight: 700;
  }
}

.resend-otp {
  .timer {
    font-weight: 700;
  }
}