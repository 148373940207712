.slides {
  background: radial-gradient(
    circle at left,
    rgb(220, 243, 250),
    rgb(252, 228, 228)
  );
  div {
    position: relative;
    img {
      width: 120px;
      aspect-ratio: 1/1;
    }
    .kid-1 {
      position: absolute;
      left: 10%;
      top: 10%;
    }
    .kid-2 {
      position: absolute;
      right: 10%;
      top: 20%;
    }
    .kid-3 {
      position: absolute;
      right: 10%;
      bottom: 10%;
    }
    .kid-4 {
      position: absolute;
      left: 10%;
      bottom: 15%;
    }
    .small-img {
      width: 80px;
      aspect-ratio: 1/1;
    }
    .tune {
      position: absolute;
      left: 45%;
      top: 15%;
    }
    .pen {
      position: absolute;
      right: 40px;
      top: 50%;
    }
    .book {
      position: absolute;
      right: 45%;
      bottom: 15%;
    }
    .thumbs-up {
      position: absolute;
      left: 45px;
      bottom: 50%;
    }
    .blob {
      width: 40px;
    }
    .Vector_1 {
      position: absolute;
      left: 8%;
      top: 6%;
    }
    .Vector_2 {
      position: absolute;
      right: 8%;
      top: 6%;
    }
    .Vector_3 {
      position: absolute;
      right: 10%;
      bottom: 25%;
    }
    .Vector_4 {
      position: absolute;
      left: 8%;
      bottom: 8%;
    }
    .main-message {
      width: 60%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .title {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        font-family: "satoshi-medium" !important;
        font-weight: 600;
        letter-spacing: 2px;
      }
      .subtitle {
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
      }
      .carousel-indicators {
        display: flex !important;
        margin-top: 24px;
        button {
          background: $primary-color;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border-top: 0;
          border-bottom: 0;
          margin-left: 12px;
          margin-right: 12px;
        }
      }
    }
  }
  // background: linear-gradient(to right, rgb(193, 230, 255), rgb(255, 241, 215));
}
