.auth-layout {
  min-height: 100vh;
  background-color: #fafbfe;
  .slides {
    width: 100%;
    flex: 1;
    .carousel {
      .carousel-inner {
        height: 100%;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  .auth-form {
    padding: 30px 60px;
    width: 100%;
    flex: 1;
    text-align: start;
    .form-head {
      .auth-form-heading {
        font-family: "satoshi-medium" !important;
        margin-left: 16px;
      }
    }
    header {
      img {
        width: 30%;
      }
    }

    form {
      background-color: white;
      border-radius: 16px;
    }
    .form-control {
      border-radius: 8px;
    }
    .form-text {
      display: block;
      margin-top: 6px !important;
    }
  }
}

.social-button {
  border: 1px rgb(187, 188, 190) solid;
  background-color: white;
  box-shadow: 0 4px rgb(187, 188, 190);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px;
  img {
    width: 30px;
    margin-right: 8px;
  }
}

.password-input {
  .form-control {
    border-right: 0;
  }
  button {
    background: #fff;
    border: 1px solid #ced4da;
    border-left: 0;
    border-radius: 8px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media screen and (max-width: 850px) {
  .auth-layout {
    .auth-form {
      padding: 16px 16px;
      header {
        img {
          width: 50%;
        }
      }
      .form-head {
        text-align: center;
        .auth-form-heading {
          margin-left: 0 !important;
        }
      }
      form {
        text-align: start;
        padding: 16px !important;
      }
    }
  }
}

@import "../../../pages/auth/loginOrRegister/components/OTPVerification/style.scss";
